//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'BlokHomeHowItWorks',
  props: {
    blok: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      isVisibleAos: false,
      isVisibleSwiper: false,
      textOptions: {
        slidesPerView: 1,
        watchOverflow: true,
        autoHeight: true,
        autoplay: {
          delay: 5000,
        },
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },
      },
    }
  },
  computed: {
    aosAnimate() {
      return this.isVisibleAos ? 'aos-animate' : null;
    },
    swiperText() {
      return this.$refs.refSwiperText.$swiper;
    },
  },
  methods: {
    aosVisibilityChanged(isVisible) {
      this.isVisibleAos = isVisible;
    },
    swiperVisibilityChanged(isVisible) {
      this.isVisibleSwiper = isVisible;

      if (isVisible) {
        this.swiperText.autoplay.start();
      } else {
        this.swiperText.autoplay.stop();
      }
    },
    autoplayStart() {
      this.$el.classList.remove('swiper-pagination-pause');
    },
    autoplayStop() {
      this.$el.classList.add('swiper-pagination-pause');
    },
    handleSwiperStop() {
      this.swiperText.autoplay.stop();
    },
    handleSwiperStart() {
      if (this.isVisibleSwiper) {
        this.swiperText.autoplay.start();
      }
    },
  },
  mounted() {
    this.swiperText
      .on('autoplayStart', () => {
        this.autoplayStart();
      })
      .on('autoplayStop', () => {
        this.autoplayStop();
      });
  },
}
