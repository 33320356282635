var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{directives:[{name:"editable",rawName:"v-editable",value:(_vm.blok),expression:"blok"},{name:"observe-visibility",rawName:"v-observe-visibility",value:({
    callback: _vm.aosVisibilityChanged,
    once: true,
    throttle: 150,
    intersection: {
      rootMargin: '-86px 0px',
      threshold: 0,
    },
  }),expression:"{\n    callback: aosVisibilityChanged,\n    once: true,\n    throttle: 150,\n    intersection: {\n      rootMargin: '-86px 0px',\n      threshold: 0,\n    },\n  }"}],staticClass:"how-works",class:{
    'swiper-pagination-stop': !_vm.isVisibleSwiper,
    'how-works--aos-animate': _vm.isVisibleAos,
  },attrs:{"id":_vm.blok.id || null}},[_c('div',{staticClass:"container"},[_c('div',{directives:[{name:"observe-visibility",rawName:"v-observe-visibility",value:({
        callback: _vm.swiperVisibilityChanged,
        intersection: {
          rootMargin: '-86px 0px',
        },
      }),expression:"{\n        callback: swiperVisibilityChanged,\n        intersection: {\n          rootMargin: '-86px 0px',\n        },\n      }"}],staticClass:"how-works__wr -g-d-f -g-ai-c"},[_c('div',{staticClass:"how-works__text"},[(_vm.blok.subtitle)?_c('div',{staticClass:"how-works__text-subtitle -t5-700",class:[_vm.aosAnimate],attrs:{"data-aos":"fade-up"},domProps:{"innerHTML":_vm._s(_vm.blok.subtitle)}}):_vm._e(),_vm._v(" "),_c('swiper',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.handleSwiperStart),expression:"handleSwiperStart"}],ref:"refSwiperText",attrs:{"options":_vm.textOptions,"cleanup-styles-on-destroy":false},on:{"click":_vm.handleSwiperStop}},[_vm._l((_vm.blok.slides),function(slide,idx){return _c('swiper-slide',{directives:[{name:"editable",rawName:"v-editable",value:(slide),expression:"slide"}],key:slide._uid},[(slide.title)?_c('h2',{staticClass:"how-works__text-title -h2 -ws-pl",class:[idx === 0 && _vm.aosAnimate],attrs:{"data-aos":idx === 0 && 'fade-up',"data-aos-delay":idx === 0 && '150'},domProps:{"innerHTML":_vm._s(slide.title)}}):_vm._e(),_vm._v(" "),(slide.description)?_c('p',{staticClass:"how-works__text-descr -t2 -ws-pl",class:[idx === 0 && _vm.aosAnimate],attrs:{"data-aos":idx === 0 && 'fade-up',"data-aos-delay":idx === 0 && '300'},domProps:{"innerHTML":_vm._s(slide.description)}}):_vm._e()])}),_vm._v(" "),_c('div',{staticClass:"swiper-pagination swiper-pagination--long-time",attrs:{"slot":"pagination"},slot:"pagination"})],2)],1),_vm._v(" "),_c('div',{staticClass:"how-works__img",class:[_vm.aosAnimate],attrs:{"data-aos":"fade-up"}},[(_vm.blok.image && _vm.blok.image.filename)?_c('img',{directives:[{name:"lazy-load",rawName:"v-lazy-load"}],attrs:{"data-src":_vm._f("sbImageService")(_vm.blok.image.filename),"alt":_vm.blok.image.alt,"width":_vm._f("sbImageSize")(_vm.blok.image.filename,'width'),"height":_vm._f("sbImageSize")(_vm.blok.image.filename,'height')}}):_vm._e()])])])])}
var staticRenderFns = []

export { render, staticRenderFns }